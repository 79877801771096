#urls {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 5%;
}
  
#urls td, #urls th {
    border: 1px solid #ddd;
    padding: 8px;
}
  
#urls tr:nth-child(even){
    background-color: #f2f2f2;
}

#urls tr:hover {
    background-color: #ddd;
}

#urls th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #007bff;
    color: white;
}